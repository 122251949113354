'use strict';function moveRibbon() {
  var elPrimaryNavHolder = document.querySelector('.g-primary-nav');
  var elRibbon = document.querySelector('header.ribbon');

  if (elPrimaryNavHolder && elRibbon) {
    elPrimaryNavHolder.before(elRibbon);
    elRibbon.style.order = -1;
    elPrimaryNavHolder.style.order = -2;
  }
}

moveRibbon();